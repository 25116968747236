import React from 'react' ;
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';
import Layout from '../components/layout.js';
import PostHeader from '../components/post-header.js';
import PostDetails from '../components/post-details.js';
import PostGallery from '../components/post-gallery.js';

export default class PostTemplate extends React.Component {

  render() {
    const post = this.props.data.wordpressPost;
    return (
      <Layout>
        <Helmet title={post.title}></Helmet>
          <article>
            <header>
              <PostHeader postTitle={post.title} 
                        alt={post.acf.post_head.alt_text}
                        backgroundImage={post.acf.post_head.localFile.childImageSharp.fluid} /> 

            </header>
            <PostDetails description ={post.content}
              periode= {post.acf.periode}
              date={post.acf.date}
              objet={post.acf.objet}
              categories={post.tags}/>
                { post.acf.galerie &&
                  <PostGallery images={post.acf.galerie} />
                }
          </article>
      </Layout>    
    )
  }

}

export const query = graphql`
  query($id : String!){
    wordpressPost(id : {eq: $id}){
      title
      content
      tags {
        name
      }
      acf {
        objet
        periode
        date
        post_head {
          alt_text
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        galerie {
          title
          alt_text
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`
