import React from 'react';
import posed from 'react-pose';
import Img from 'gatsby-image';

const Wrapper = posed.div({
  initial: {opacity: 0, x: 40},
  mount: {opacity: 1, x: 0, delayChildren: 200, transition: {duration: 500}} 
});

const PostHeader = ({ postTitle, backgroundImage, alt }) => (
  
  <Wrapper className="post-header" initialPose="initial" pose="mount">

    <Wrapper className="content" initialPose="initial" pose="mount">
      <div className="inner-container">
        <h1 dangerouslySetInnerHTML={{__html : postTitle }} />
      </div>
    </Wrapper>

    <div className="background">
      {backgroundImage &&
        <Img fluid={backgroundImage} className="background-image" alt={alt}/>
      }  
    </div>
      
  </Wrapper>

)

export default PostHeader ;
