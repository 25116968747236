import React from 'react';
import Img from 'gatsby-image';

const PostGallery = ({ images }) => {
  return (

    <div className='post-gallery'>
      <div className='inner-container'>

        <ul className='post-gallery-container'>
          {images.map(image => (
            <li className={'post-gallery-img ' + (image.localFile.childImageSharp.fluid.aspectRatio <= 1 ? "vertical" : "horizontal") } key={image.localFile.name}>
              <Img  fluid={image.localFile.childImageSharp.fluid} 
                    alt={image.alt_text} 
                    title={image.title}/>
            </li>
          ))}
        </ul>

      </div>
    </div>

  )
}

export default PostGallery ;
