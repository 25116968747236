import React from 'react';

const PostDetails = ({ description, date, periode, objet, categories }) => {

  const tags = categories && categories.map( tag => tag.name ).join(', ') ;
  
  return (
    <div className="post-info">
        
      <div className="inner-container">
        <div className="post-info-container">
          <div className="post-description" dangerouslySetInnerHTML={{ __html : description }} />
          <div className="post-details">
            <div className="field object">
              <span className="field-header">Objet</span>
              <div className="field-content" dangerouslySetInnerHTML={{ __html : objet }} />
            </div>
            <div className="field period">
              <span className="field-header">Période</span>
              <div className="field-content" dangerouslySetInnerHTML={{ __html : periode }} />
            </div>
            <div className="field date">
              <span className="field-header">Date</span>
              <div className="field-content" dangerouslySetInnerHTML={{ __html : date }} />
            </div>
            {categories &&
              <div className="field categories">
                <span className="field-header">Catégorie</span>
                <div className="field-content" dangerouslySetInnerHTML={{ __html : tags }} />
              </div>
            }
          </div>
        </div>
      </div>

    </div>
  )

}

export default PostDetails ;

